import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import "./top-menu-dropdown.style.scss";

import NavigationItems from "./components/navigation-items/navigation-items.components";

import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

import logo from '../../assets/logo.svg'

function TopMenuDropdown(props) {
  const [data, setData] = useState([]);

  const history = useHistory();

  useEffect(() => {
    switch (props.navigationItems.dataSource) {
      default: {
        setData(props.navigationItems.data);
      }
    }
  }, [props.navigationItems.dataSource, props.navigationItems.data]);

  return (
    <div className='top-menu-dropdown'>
      <div className="upper-menu">
        <div className='description'>
          <div className='email'>
            <div className='title'>Επικοινωνία</div>
            <EmailIcon className='icon' />
          </div>
          <div className='schedule'>{props.upperMenu.schedule}</div>
          <div className='call'>
            <PhoneIcon className='icon' />
            <div className='title'>{`Καλέστε στο ${props.upperMenu.phoneNumber}`}</div>
          </div>
        </div>
      </div>
      <div
        className="mennu"
        style={{
          display: "flex",
          padding: props.padding,
          alignContent: props.alignContent,
          justifyContent: props.justifyContent,
        }}
      >
        <img
          onClick={() => history.push('/')}
          alt="logo"
          className='logo'
          src={logo}
        />
        <NavigationItems margin={props.navigationItems.margin} data={data} />
        <div></div>
      </div>
    </div>
  );
}

export default TopMenuDropdown;
