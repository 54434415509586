import React from "react";

import "./home-page.style.scss";

// internal
import YoutubePlayer from "../../components/youtube-player/youtube-player.component.jsx";
import BannerCardList from "../../components/banner-card-list/banner-card-list.component";
import TravelPackageCarousel from "../../components/travel-package-carousel/travel-package-carousel.component";
import TravelPackageFilter from "../../components/travel-package-filter/travel-package-filter.component"

function HomePage() {
  return (
    <div className="home-page">
      <YoutubePlayer url="https://www.youtube.com/watch?v=P2EeaVlzvDs" />
      <TravelPackageFilter />
      <div
        style={{
          height: "500px",
          display: "flex",
          flexDirection: "column",
          padding: "50px 0",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundImage: `url('https://cdn.mos.cms.futurecdn.net/Tw4oCiJrKzJLgHrwMHuRHf.jpg')`,
          position: "relative",
        }}
      >
        <h1
          style={{
            color: "black",
            marginBottom: "10px",
          }}
        >
          Καλοκαιρινές αποδράσεις
        </h1>
        <div style={{ width: "100%" }}>
          <TravelPackageCarousel
            packages={[
              {
                id: 1,
                imageURL:
                  "https://www.remax.eu/uploads/agent-1/Greece%20(Optimized)-5e580fa45d9f1.jpg",
                title: "Ελλάδα",
                description:
                  "Οργανωμένες εκδρομές Αγίου Πνεύματος και Καλοκαίρι στην Ελλάδα",
              },
              {
                id: 2,
                imageURL:
                  "https://www.remax.eu/uploads/agent-1/Greece%20(Optimized)-5e580fa45d9f1.jpg",
                title: "Ελλάδα",
                description:
                  "Οργανωμένες εκδρομές Αγίου Πνεύματος και Καλοκαίρι στην Ελλάδα",
              },
              {
                id: 3,
                imageURL:
                  "https://www.remax.eu/uploads/agent-1/Greece%20(Optimized)-5e580fa45d9f1.jpg",
                title: "Ελλάδα",
                description:
                  "Οργανωμένες εκδρομές Αγίου Πνεύματος και Καλοκαίρι στην Ελλάδα",
              },
              {
                id: 4,
                imageURL:
                  "https://www.remax.eu/uploads/agent-1/Greece%20(Optimized)-5e580fa45d9f1.jpg",
                title: "Ελλάδα",
                description:
                  "Οργανωμένες εκδρομές Αγίου Πνεύματος και Καλοκαίρι στην Ελλάδα",
              },
              {
                id: 5,
                imageURL:
                  "https://www.remax.eu/uploads/agent-1/Greece%20(Optimized)-5e580fa45d9f1.jpg",
                title: "Ελλάδα",
                description:
                  "Οργανωμένες εκδρομές Αγίου Πνεύματος και Καλοκαίρι στην Ελλάδα",
              },
              {
                id: 6,
                imageURL:
                  "https://www.remax.eu/uploads/agent-1/Greece%20(Optimized)-5e580fa45d9f1.jpg",
                title: "Ελλάδα",
                description:
                  "Οργανωμένες εκδρομές Αγίου Πνεύματος και Καλοκαίρι στην Ελλάδα",
              },
              {
                id: 7,
                imageURL:
                  "https://www.remax.eu/uploads/agent-1/Greece%20(Optimized)-5e580fa45d9f1.jpg",
                title: "Ελλάδα",
                description:
                  "Οργανωμένες εκδρομές Αγίου Πνεύματος και Καλοκαίρι στην Ελλάδα",
              },
            ]}
          />
        </div>
      </div>

      <BannerCardList
        banners={[
          {
            id: 1,
            imageURL:
              "https://www.remax.eu/uploads/agent-1/Greece%20(Optimized)-5e580fa45d9f1.jpg",
            title: "Ελλάδα",
            description:
              "Οργανωμένες εκδρομές Αγίου Πνεύματος και Καλοκαίρι στην Ελλάδα",
            width: "50%",
          },
          {
            id: 2,
            imageURL:
              "https://www.remax.eu/uploads/agent-1/Greece%20(Optimized)-5e580fa45d9f1.jpg",
            title: "Ελλάδα",
            description:
              "Οργανωμένες εκδρομές Αγίου Πνεύματος και Καλοκαίρι στην Ελλάδα",
            width: "25%",
          },
          {
            id: 3,
            imageURL:
              "https://www.remax.eu/uploads/agent-1/Greece%20(Optimized)-5e580fa45d9f1.jpg",
            title: "Ελλάδα",
            description:
              "Οργανωμένες εκδρομές Αγίου Πνεύματος και Καλοκαίρι στην Ελλάδα",
            width: "25%",
          },
          {
            id: 4,
            imageURL:
              "https://www.remax.eu/uploads/agent-1/Greece%20(Optimized)-5e580fa45d9f1.jpg",
            title: "Ελλάδα",
            description:
              "Οργανωμένες εκδρομές Αγίου Πνεύματος και Καλοκαίρι στην Ελλάδα",
            width: "25%",
          },
          {
            id: 5,
            imageURL:
              "https://www.remax.eu/uploads/agent-1/Greece%20(Optimized)-5e580fa45d9f1.jpg",
            title: "Ελλάδα",
            description:
              "Οργανωμένες εκδρομές Αγίου Πνεύματος και Καλοκαίρι στην Ελλάδα",
            width: "25%",
          },
          {
            id: 6,
            imageURL:
              "https://www.remax.eu/uploads/agent-1/Greece%20(Optimized)-5e580fa45d9f1.jpg",
            title: "Ελλάδα",
            description:
              "Οργανωμένες εκδρομές Αγίου Πνεύματος και Καλοκαίρι στην Ελλάδα",
            width: "50%",
          },
        ]}
      />
    </div>
  );
}

export default HomePage;
