import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./pages/home-page/home-page.component.jsx"
import CatalogPage from "./pages/catalog-page/catalog-page.componen.jsx";
import TopMenuDropdown from "./components/top-menu-dropdown/top-menu-dropdown.component.jsx";
import {BrowserRouter as Router,Route, Switch} from'react-router-dom';
function App() {
  return (
    <Router>
      <div>
        <TopMenuDropdown
          padding="10px"
          upperMenu={{
            schedule: "Δευ - Παρ 09:00 - 19.00 & Σαβ 10:00 - 17:00",
            phoneNumber: "211 23 41 212",
          }}
          logo={{
            url: "https://banner2.cleanpng.com/20180401/yiw/kisspng-apple-logo-svg-5ac07610d08f17.2515969615225625768543.jpg",
            width: "35px",
            height: "35px",
          }}
          navigationItems={{
            dataSource: "local",
            margin: "0 0 0 90px",
            data: [
              {
                id: 1,
                label: "ΠΡΟΟΡΙΣΜΟΙ",
                type: "text",
                color: "white",
                colorOnHover: "#00baf2",
                fontWeight: 500,
                onClick: {
                  type: "navigate",
                  url: "/Προορισμοί",
                },
                children: [
                  {
                    id: 2,
                    label: "ΑΣΙΑ",
                    type: "text",
                    colorOnHover: "#00baf2",
                    onClick: {
                      type: "navigate",
                      url: "/Ελλάδα",
                    },
                    children: [
                      {
                        id: 3,
                        label: "ΚΙΝΑ",
                        type: "text",
                        colorOnHover: "#00baf2",
                        onClick: {
                          type: "navigate",
                          url: "/Ελλάδα",
                        },
                      },
                      {
                        id: 4,
                        label: "ΙΑΠΩΝΙΑ",
                        type: "text",
                        colorOnHover: "#00baf2",
                        onClick: {
                          type: "navigate",
                          url: "/Ελλάδα",
                        },
                      },
                    ],
                  },
                  {
                    id: 4,
                    label: "ΕΥΡΩΠΗ",
                    type: "text",
                    colorOnHover: "#00baf2",
                    onClick: {
                      type: "navigate",
                      url: "/ΕΥΡΩΠΗ",
                    },
                    children: [
                      {
                        id: 5,
                        label: "ΕΛΛΑΔΑ",
                        colorOnHover: "#00baf2",
                        onClick: {
                          type: "navigate",
                          url: "/ΙΣΠΑΝΙΑ",
                        },
                        children: [
                          {
                            id: 1,
                            label: "ΙΟΝΙΑ ΝΗΣΙΑ",
                            colorOnHover: "#00baf2",
                            onClick: {
                              type: "navigate",
                              url: "/ΙΣΠΑΝΙΑ",
                            },
                            children: [
                              {
                                id: 1,
                                label: "ΛΕΥΚΑΔΑ",
                                colorOnHover: "#00baf2",
                                onClick: {
                                  type: "navigate",
                                  url: "/ΙΣΠΑΝΙΑ",
                                },
                              },
                              {
                                id: 2,
                                label: "ΚΕΡΚΥΡΑ",
                                colorOnHover: "#00baf2",
                                onClick: {
                                  type: "navigate",
                                  url: "/ΙΣΠΑΝΙΑ",
                                },
                              },
                            ],
                          },
                          {
                            id: 2,
                            label: "ΚΥΚΛΑΔΕΣ",
                            colorOnHover: "#00baf2",
                            onClick: {
                              type: "navigate",
                              url: "/ΙΣΠΑΝΙΑ",
                            },
                            children: [
                              {
                                id: 1,
                                label: "ΝΑΞΟΣ",
                                colorOnHover: "#00baf2",
                                onClick: {
                                  type: "navigate",
                                  url: "/ΙΣΠΑΝΙΑ",
                                },
                              },
                              {
                                id: 2,
                                label: "ΑΜΟΡΓΟΣ",
                                colorOnHover: "#00baf2",
                                onClick: {
                                  type: "navigate",
                                  url: "/ΙΣΠΑΝΙΑ",
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 6,
                        label: "ΑΓΓΛΙΑ",
                        colorOnHover: "#00baf2",
                        onClick: {
                          type: "navigate",
                          url: "/ΑΓΓΛΙΑ",
                        },
                      },
                    ],
                  },
                  {
                    id: 5,
                    label: "ΑΜΕΡΙΚΗ",
                    type: "text",
                    colorOnHover: "#00baf2",
                    onClick: {
                      type: "navigate",
                      url: "/ΕΥΡΩΠΗ",
                    },
                  },
                ],
              },
              {
                id: 10,
                label: "ΠΑΚΕΤΑ",
                type: "text",
                color: "white",
                colorOnHover: "#00baf2",
                fontWeight: 500,
                onClick: {
                  type: "navigate",
                  url: "/Προορισμοί",
                },
              },
              {
                id: 11,
                label: "ΣΧΕΔΙΑΖΟΥΜΕ ΜΑΖΙ",
                type: "text",
                color: "white",
                colorOnHover: "#00baf2",
                fontWeight: 500,
                onClick: {
                  type: "navigate",
                  url: "/Προορισμοί",
                },
              },
              {
                id: 12,
                label: "ΤΑ ΕΝΤΥΠΑ ΜΑΣ",
                type: "text",
                color: "white",
                colorOnHover: "#00baf2",
                fontWeight: 500,
                onClick: {
                  type: "navigate",
                  url: "/Προορισμοί",
                },
              },
              {
                id: 13,
                label: "BLOG",
                type: "text",
                color: "white",
                colorOnHover: "#00baf2",
                fontWeight: 500,
                onClick: {
                  type: "navigate",
                  url: "/Προορισμοί",
                },
              },
              {
                id: 14,
                label: "ΞΕΝΟΔΟΧΕΙΑ",
                type: "text",
                color: "white",
                colorOnHover: "#00baf2",
                fontWeight: 500,
                onClick: {
                  type: "navigate",
                  url: "/Προορισμοί",
                },
              },
              {
                id: 7,
                label: "Online Booking",
                type: "button",
                color: "black",
                colorOnHover: "#00baf2",
                backgroundColor: "#f93a27",
                fontWeight: 500,
                onClick: {
                  type: "navigate",
                  url: "/Προορισμοί",
                },
              },
            ],
          }}
        />
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/catalog">
            <CatalogPage />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
