import React, { useEffect, useState } from 'react';

import './banner-card-list.style.scss'

import BannerCard from '../banner-card/banner-card.component'

function BannerCardList(props) {
    const [banners, setBanners] = useState([])

    useEffect(() => {

        if(!props?.banners) return;
        setBanners(props.banners)
    },  [props.banners])

    return (
        <div className='banner-card-list'>
            {
                banners.map(x => {
                    return (
                        <div className='banner-card' style={{ minWidth: x.width }} key={x.id}>
                             <BannerCard imageURL={x.imageURL} title={x.title} description={x.description} width={x.width} />
                        </div>
                    )
                })
            }
        </div>
    );
}

export default BannerCardList;