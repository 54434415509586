import './travel-package-list.style.scss';

// internal
import TravelPackageCard from '../../../components/travel-package-card/travel-package-card.component'

function TravelPackageList() {
    return (
        <div className='travel-package-list'>
            <div className='filter'>
                <div>Βρέθηκαν <span className='quantity'>30</span> διαθέσιμα πακέτα διακοπών</div>
                <div>Τιμές</div>
            </div>
            <div className='packages'>
                <div className="package">
                    <TravelPackageCard  width="400px" imageURL="https://www.manessistravel.gr/_storageWeb_/n/17777020530427/ig/17469038976422/b/692055490556/isk/listThumb/shutterstock_1936127161.jpeg" />
                </div>
                <div className="package">
                    <TravelPackageCard  width="400px" imageURL="https://www.manessistravel.gr/_storageWeb_/n/17777020530427/ig/17469038976422/b/692055490556/isk/listThumb/shutterstock_1936127161.jpeg" />
                </div>
                <div className="package">
                    <TravelPackageCard  width="400px" imageURL="https://www.manessistravel.gr/_storageWeb_/n/17777020530427/ig/17469038976422/b/692055490556/isk/listThumb/shutterstock_1936127161.jpeg" />
                </div>
                <div className="package">
                    <TravelPackageCard  width="400px" imageURL="https://www.manessistravel.gr/_storageWeb_/n/17777020530427/ig/17469038976422/b/692055490556/isk/listThumb/shutterstock_1936127161.jpeg" />
                </div>
                <div className="package">
                    <TravelPackageCard  width="400px" imageURL="https://www.manessistravel.gr/_storageWeb_/n/17777020530427/ig/17469038976422/b/692055490556/isk/listThumb/shutterstock_1936127161.jpeg" />
                </div>
                <div className="package">
                    <TravelPackageCard  width="400px" imageURL="https://www.manessistravel.gr/_storageWeb_/n/17777020530427/ig/17469038976422/b/692055490556/isk/listThumb/shutterstock_1936127161.jpeg" />
                </div>
                <div className="package">
                    <TravelPackageCard  width="400px" imageURL="https://www.manessistravel.gr/_storageWeb_/n/17777020530427/ig/17469038976422/b/692055490556/isk/listThumb/shutterstock_1936127161.jpeg" />
                </div>
                <div className="package">
                    <TravelPackageCard  width="400px" imageURL="https://www.manessistravel.gr/_storageWeb_/n/17777020530427/ig/17469038976422/b/692055490556/isk/listThumb/shutterstock_1936127161.jpeg" />
                </div>
            </div>
        </div>
    )
}

export default TravelPackageList;