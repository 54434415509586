import React, { useEffect, useState } from "react";
import "./navigation-item.style.scss";

import { v4 as uuidv4 } from "uuid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from '@material-ui/core/Button';
import NavigationTreeList from "../navigation-tree-list/navigation-tree-list.component";
import ScheduleIcon from '@material-ui/icons/Schedule';

function NavigationItem(props) {
  const [label, setLabel] = useState("");
  const [id] = useState(uuidv4());
  const [opened, setOpen] = useState(false);
  const [data, setData] = useState([]);

  function onMouseEnter() {
    if(!document.getElementById(`${id}-label`)){
      return;
    }
    document.getElementById(`${id}-label`).style.color = props.colorOnHover;
    document.getElementById(`${id}-icon`).style.color = props.colorOnHover;
    setOpen(true);
  }

  function onMouseLeave() {
    if(!document.getElementById(`${id}-label`)){
      return;
    }
    document.getElementById(`${id}-label`).style.color = props.color;
    document.getElementById(`${id}-icon`).style.color = props.color;
    setOpen(false);
  }

  useEffect(() => {
    if (props.translations) {
      setLabel("");
    }
    setLabel(props.label ?? "");

    if (props.children) {
      setData(props.children);
    }
  }, [props.translations, props.label, props.children])

  return (
    <div
      className="navigation-item"
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
    >
      {props.type === "text" ? (
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            id={`${id}-label`}
            className="label"
            style={{
              fontWeight: props.fontWeight,
              color: props.color,
            }}
          >
            {label}
          </div>
          <ExpandMoreIcon
            id={`${id}-icon`}
            style={{
              display: data.length > 0 ? "block" : "none",
              color: 'white',
              fontSize: "16pt",
            }}
          />
          <div
            className="tree"
            style={{
              display: opened ? "block" : "none",
            }}
          >
            <div style={{
              height: '40px'
            }}></div>
            <NavigationTreeList data={data} />
          </div>
        </div>
      ) : (
        <div>
        <Button
          style={{
            backgroundColor: props.backgroundColor,
            borderRadius: '20px'
          }}
          variant="contained"
          color="secondary"
          startIcon={<ScheduleIcon fontSize="small" />}
        >
          {props.label}
        </Button>
        </div>
      )}
    </div>
  );
}

export default NavigationItem;
