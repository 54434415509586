import React from "react";

import './youtube-player.style.scss'
import ReactPlayer from 'react-player'

function YoutubePlayer(props) {
  return (
    <div className="youtube-player">
      <ReactPlayer 
        className="video-frame"
        url={props.url}
        loop={true}
        playing={true}
        controls={false}
        muted={true}
        width="1920px"
        height="1080px"
        config={{
          youtube: {
            playerVars: { showinfo: 1 }
          }
        }}
      />
    </div>
  );
}

export default YoutubePlayer;
