import React from "react";
import "./navigation-items.style.scss";

import NavigationItem from "../navigation-item/navigation-item.components";

function NavigationItems(props) {
  return (
    <div className="navigation-items" style={{margin: props.margin}}>
        {
            props.data.map(({id, ...navigationItem}, index) => {
                return (
                  <div className='item' key={index}>
                    <NavigationItem {...navigationItem} />
                  </div>
                );
            })
        }
    </div>
  );
}

export default NavigationItems;
