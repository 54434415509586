import "./travel-package-filter.style.scss";

import InputSelect from "../../@inputs/input-select/input-select.component";

import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";

function TravelPackageFilter() {
  return (
    <div className="travel-package-filter">
      <InputSelect
        id="location"
        width="200px"
        label="Επιλέξτε Προορισμό"
        selectOptions={[
          {
            id: "europe",
            name: "Europe",
          },
          {
            id: "greece",
            name: "Greece",
            parentId: "europe",
          },
          {
            id: "usa",
            name: "USA",
          },
        ]}
      />
      <InputSelect
        id="proposal"
        width="200px"
        label="Επιλέξτε Κατηγορία"
        selectOptions={[
          {
            id: "1",
            name: "Προτάσεις για Ελλάδα",
          },
          {
            id: "2",
            name: "Κρουαζιέρες",
          },
          {
            id: "3",
            name: "Ατομικές Αποδράσεις",
          },
        ]}
      />
      <InputSelect
        id="location"
        width="200px"
        label="Επιλέξτε Περίοδο"
        selectOptions={[
          {
            id: "1",
            name: "Weekends",
          },
          {
            id: "2",
            name: "Χριστούγεννα 2021",
          },
          {
            id: "3",
            name: "Καλοκαίρι 2021",
          },
        ]}
      />
      <Button
        style={{
          height: "50px",
          marginLeft: "10px",
        }}
        variant="contained"
        color="default"
        startIcon={<SearchIcon />}
      >
        Αναζητηση
      </Button>
    </div>
  );
}

export default TravelPackageFilter;
