import React, { useEffect, useState } from "react";
import "./navigation-tree-item.style.scss";

import NavigationTreeList from "../navigation-tree-list/navigation-tree-list.component";
import { useHistory } from 'react-router-dom';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { v4 as uuidv4 } from "uuid";



function NavigationTreeItem(props) {
  const [id] = useState(uuidv4());
  const [opened, setOpen] = useState(false);
  const [data, setData] = useState([]);

  
  const history = useHistory();

  function onMouseEnter() {
    document.getElementById(`${id}`).style.color = props.colorOnHover;
    const width = document.getElementById(`${id}`).clientWidth;
    document.getElementById(`${id}-tree`).style.position = 'absolute';
    document.getElementById(`${id}-tree`).style.left = `${width - 5}px`;
    document.getElementById(`${id}-tree`).style.top = `0`;
    setOpen(true);
  }

  function onMouseLeave() {
    document.getElementById(`${id}`).style.color = props.color;
    setOpen(false);
  }

  function onClick () {
    setOpen(false);
    history.push('/catalog')
  }

  useEffect(() => {
    if (props.children) {
      setData(props.children);
    }
  }, [props.children]);

  return (
    <div
      id={id}
      className="navigation-tree-item"
      onClick={() => onClick()}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
    >
      <div 
        id={`${id}-title`} 
        className='title'
        style={{
            color: opened ? props.colorOnHover : '#838393',
        }}
      >
          {props.label}
      </div>
      <NavigateNextIcon
        id={`${id}-icon`}
        style={{
          fontSize: "12pt",
          color: opened ? props.colorOnHover : '#838393',
          display: data.length > 0 ? "block" : "none",
        }}
      />
      <div  
        id={`${id}-tree`}
        style={{
           display: opened ? "block" : "none",
         }}
      >
      <NavigationTreeList
        data={data}
      />
      </div>

    </div>
  );
}

export default NavigationTreeItem;
