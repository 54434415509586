import React, { useEffect, useState } from 'react';

import './travel-package-carousel.style.scss';

import TravelPackageCard from '../travel-package-card/travel-package-card.component';

import Slider from "react-slick";

function TravelPackageCarousel(props) {
    const [packages, setPackages] = useState([]);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 6,
        responsive: [

              {
                breakpoint: 1700,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 4,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 1410,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 1070,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 720,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
        ]
      };

    useEffect(() => {
        if(!props.packages) return;

        setPackages(props.packages);
    }, [props.packages]);

    return (
        <div className='travel-package-carousel'>
            <Slider {...settings}>
            {
                packages.map(x => {
                    return (
                        <div className='travel-package' key={x.id}>
                            <TravelPackageCard imageURL={x.imageURL} />
                        </div>
                    )
                })
            }
            </Slider>
        </div>
    );
}

export default TravelPackageCarousel;