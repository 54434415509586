import React from "react";

import "./travel-package-card.style.scss";

import Card from "@material-ui/core/Card";
import AccessTimeIcon from '@material-ui/icons/AccessTime';

function TravelPackageCard(props) {
  return (
    <div style={{width: props.width}} className="travel-package-card">
      <Card>
        <div className="image-card">
          <img alt={props.title} src={props.imageURL} />
          <div className="information">
            <div className="starting-price shadow">
              <div className="starting-price-button">από $725</div>
            </div>
            <div className="duration">
                <AccessTimeIcon />
                <div className='days'>6 ημέρες / 5 νύχτες</div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="title">Ντουμπάι & Σαφάρι στην Έρημο</div>
          <div className="description">Ντουμπάι - Άμπου Ντάμπι</div>
        </div>
      </Card>
    </div>
  );
}

export default TravelPackageCard;
