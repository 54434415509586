import React, { useEffect, useState } from "react";
import "./navigation-tree-list.style.scss";

import NavigationTreeItem from "../navigation-tree-item/navigation-tree-item.component";

function NavigationTreeList(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  return (
    <div className="navigation-tree-list">
      <div className='shadow item'>
        {data.map(({ id, ...item }) => {
            return <NavigationTreeItem key={id} label={item.label} {...item} />;
        })}
      </div>
    </div>
  );
}

export default NavigationTreeList;
