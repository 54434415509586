import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import RefreshIcon from '@material-ui/icons/Refresh';

import './input-select.style.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function InputSelect(props) {
  const classes = useStyles();

  const [value, setValue] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue?.isPlaceholder) {
      setValue("");
      return;
    }
    setValue(selectedValue);
  };

  useEffect(() => {
    if (props.selectOptions) {
      setSelectOptions(props.selectOptions);
    }

    if (props.value) {
      setValue(props.value);
    }
  }, [props.selectOptions, props.value]);

  return (
    <div className="input-select" style={{ width: props.width }}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id={props.id}
          value={value}
          label={props.label}
          onChange={handleChange}
        >
          {selectOptions.length > 0 && value !== '' ? (
            <MenuItem value="" style={{ paddingLeft: "10px" }}>
              <div className="clear">
                <RefreshIcon />
                <div>Επαναφορά</div>
              </div>
            </MenuItem>
          ) : null}
          {selectOptions.map((x) => {
            return (
              <MenuItem
                key={x.id}
                value={x}
                style={{ paddingLeft: x.parentId ? "15px" : "10px" }}
              >
                <div>{x.name}</div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default InputSelect;
