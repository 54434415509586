import "./catalog-page.style.scss";

// internal
import TravelPackageList from './components/taravel-package-list.component';
import TravelPackageFilter from '../../components/travel-package-filter/travel-package-filter.component'

function CatalogPage() {
  return (
    <div className="catalog-page">
      <div
        className="catalog-page-header"
        style={{
          backgroundImage: `url('https://www.manessistravel.gr/_storageWeb_/n/17631564857444/ig/17469038976422/b/692055490556/isk/innerWide/shutterstock_219639736.jpeg')`,
        }}
      >
        <h1 className='title'>Ελλάδα</h1>
        {/* <img
          src="https://www.manessistravel.gr/_storageWeb_/n/17631564857444/ig/17469038976422/b/692055490556/isk/innerWide/shutterstock_219639736.jpeg"
          className="catalog-page-header-img"
        /> */}
      </div>
      <TravelPackageFilter />
      <TravelPackageList />
    </div>
  );
}

export default CatalogPage;
