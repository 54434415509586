import React from "react";

import "./banner-card.style.scss";

import Card from "@material-ui/core/Card";

function BannerCard(props) {
  return (
    <div
      className="banner-card"
      style={{
        backgroundImage: `url('${props.imageURL}')`,
        backgroundSize: 'cover',
        backgroundClip: 'content-box',
      }}
    >
      <Card>
        <div>

        </div>
        <div className='content'>
            <div className="title">{props.title}</div>
            <div className="description">{props.description}</div>
        </div>

        {/* <img src={props.imageURL} alt={props.title} /> */}
      </Card>
    </div>
  );
}

export default BannerCard;
